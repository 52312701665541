<template>
    <div>
        <div id="fullpage" class="home-page-fullpage">
            <div class="section" style="cursor: pointer">
                <div class="video-div">
                    <video
                        id="video1"
                        class="video"
                        poster="@/assets/images/home/banner_1.jpg"
                        src="https://img.zczy56.com/zhongzi/banner.mp4"
                        muted
                        autoplay
                        loop
                        v-show="videoShowAfterPage"
                    >
                        your browser does not support the video tag
                    </video>
                    <div class="slogan">
                        <img v-if="showText3" src="@/assets/images/home/banner_text_3.svg" alt="">
                        <img v-if="showText2" src="@/assets/images/home/banner_text_2.svg" alt="">
                    </div>
                </div> 
                <div class="data-link-con">
                    <div 
                        v-for="item in linkList" :key="item.id"
                        class="data-link-item" 
                        @click="clickGoLink(item)">
                        <div class="data-link-item-img">
                            <img :src="item.pic"/>
                        </div>
                        <div class="data-link-text">
                            {{item.text + ' >'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="section home-friends">
                <div class="partners_wrapper">
                    <div class="partners_content">
                        <div class="title">
                            合作伙伴
                        </div>
                        <div class="partners_list" id="partners_icon_2">
                            <div class="partners_list_item">
                                <a href="https://www.crmsc.com.cn/" target="_blank">
                                    <img src="@/assets/images/home/logo_zgtw.png" alt="">
                                </a>
                                <a href="https://www.cnsodata.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_zzsj.png" alt="">
                                </a>
                                <a href="https://www.jdl.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_jdwl.png" alt="">
                                </a>
                                 <a href="https://www.zczy56.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_zczy.png" alt="">
                                </a>
                                <a href="https://crml.crmsc.com.cn/" target="_blank">
                                    <img src="@/assets/images/home/logo_ztxdwl.png" alt="">
                                </a>
                            </div>
                            <!-- <div class="partners_list_line" style="width: 1300px;"></div>
                            <div class="partners_list_item">
                                 <a href="https://www.chinalogisticsgroup.com.cn/" target="_blank">
                                    <img src="@/assets/images/home/logo_zgwl.png" alt="">
                                </a>
                               
                               
                                <a href="https://www.sf-express.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_sfsy.png" alt="">
                                </a>
                                <a href="https://www.sto.cn/" target="_blank">
                                    <img src="@/assets/images/home/logo_stkd.png" alt="">
                                </a>
                                <a href="https://www.zto.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_ztkd.png" alt="">
                                </a>
                                
                                <a href="https://www.ane56.com/" target="_blank">
                                    <img src="@/assets/images/home/logo_an.png" alt="">
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <div class="partners_bg" id="partners_bg_1">
                        <img src="@/assets/images/home/partners_bg.png" alt="">
                    </div>
                    <div class="partners_bg" id="partners_bg_2">
                        <img src="@/assets/images/home/partners_bg_2.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="系统通知"
            :visible.sync="dialogVisible"
            width="30%"
            custom-class="system-notice-dialog"
            :close-on-click-modal="false"
            @close="hanldeClose"
            >
                <div class="system-notice">
                    <p>
                        <img src="@/assets/images/home/jqqd.png"/>
                    </p>
                    <p>系统正在建设中，敬请期待</p>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="hanldeClose">确认</el-button>
                </span>
            </el-dialog>
    </div>
</template>
<script>
import countTo from 'vue-count-to'
import { loginService } from '@/services/login'
export default {
    name: 'OfficialIndex',
    components: { countTo },
    data() {
        return {
            sectionHeight: 600,
            citysNumber: 0,
            endDate: '2020年11月13日',
            membersNumber: 0,
            spaceNumber: 0,
            weightNumber: 0,
            videoShowAfterPage: false,
            linkList: [{
                text: '鹰眼平台',
                pic: require('@/assets/images/home/yypt.png'),
                id:'yypt',
                link: ''
            },{
                text: '区块链平台',
                pic: require('@/assets/images/home/qklpt.png'),
                id:'qklpt',
                link: 'https://www.zczy56.com/bchain/#/login'
            },{
                text: '物流指数网',
                pic: require('@/assets/images/home/wlzsw.png'),
                id:'wlzsw',
                link: 'https://data.zczy100.com/#/home'
            },{
                text: '应急物流',
                pic: require('@/assets/images/home/yywl.png'),
                id:'yywl',
                link: 'https://zy.zczy56.com/#/login'
            },{
                text: '信用分',
                pic: require('@/assets/images/home/xyf.png'),
                id:'xyf',
                link: '/creditScore.html'
            }],
            slideIndex: 1,
            showText3: true,
            showText2: false,
            swiperTime: null,
            dialogVisible: true
        }
    },
    mounted() {
        this.jqueryReady()
        this.sectionHeight = document.documentElement.clientHeight
        let vm = this;
        window.onresize = () => {
            this.sectionHeight = document.documentElement.clientHeight
        }
        this.blockChainService('bchain');
        loginService
            .homeShowNumbers({})
            .then(res => {
                this.citysNumber = res.citysNumber
                this.endDate = res.endDate
                this.membersNumber = res.membersNumber
                this.spaceNumber = res.spaceNumber
                this.weightNumber = res.weightNumber
            })
            .catch(err => {
                //
            })

         this.swiperTime = setInterval(() => {
            if(vm.showText3){
                vm.showText3 = false;
                vm.showText2 = true;
            }else {
                vm.showText2 = false;
                vm.showText3 = true;
            }
            $('.slogan').removeClass('slogan-show');
            vm.$nextTick(() => {
                $('.slogan').addClass('slogan-show')
            })
        }, 9000);
        this.$nextTick(() => {
            $('.v-modal').addClass('model-home').addClass('model-hide')
            $('.el-dialog__wrapper').addClass('model-home').addClass('model-hide')
            $('.system-notice-dialog').addClass('model-hide')
        })
        
    },
    methods: {
        hanldeClose(){
            $('.v-modal').addClass('model-home').addClass('model-hide')
            $('.el-dialog__wrapper').addClass('model-home').addClass('model-hide')
            $('.system-notice-dialog').addClass('model-hide')
        },
        blockChainService(name) {
            const iframeDom = document.createElement('iframe')
            const url = 'https://www.zczy56.com/bchain/#/login'
            iframeDom.src = url
            iframeDom.id  = name ; 
            iframeDom.style="display: none";// 页面出现双滚动条
            document.body.appendChild(iframeDom)
            // iframeDom.onload = function() {
            //     window.open(url).focus()
            //     const iframeDom = document.getElementById(name);
            //     document.body.removeChild(iframeDom)
            //   }
        },
        clickGoLink(item){
            if(item.id == 'yypt'){
                // if(location.href.indexOf('zczy') !== -1){
                //     window.open('https://eye.zczy56.com/#/autoLogin?name=diaodu')
                // }else if(location.href.indexOf('172') !== -1){
                //     window.open('http://172.20.20.23:9099/#/autoLogin?name=fangjuan')
                // }
                // else{
                //     window.open('http://192.168.87.88:8082/#/autoLogin?name=fangjuan')
                // } 
                window.open('https://eye.zczy56.com/#/login')
                return;
            }
            if(item.id == 'qklpt'){
                const url = "https://www.zczy56.com/bchain/#/login";
                window.open(url).focus();
                const iframeDom = document.getElementById('bchain');
                document.body.removeChild(iframeDom);
                return;
            }
            window.open(item.link);
        },
        jqueryReady() {
            function IsPC() {
                var userAgentInfo = navigator.userAgent
                var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
                var flag = true
                for (var v = 0; v < Agents.length; v++) {
                    if (userAgentInfo.indexOf(Agents[v]) > 0) {
                        flag = false
                        break
                    }
                }
                return flag
            }

            var isPcBool = IsPC() //true为PC端，false为手机端

            var _this = this
            var video1 = document.getElementById('video1')
            var video2 = document.getElementById('video2');
            
            $(window.docuemnt).ready(function () {
                // $('#page_header').addClass('hasTopBanner')

                if (isPcBool) {
                    $('#fullpage').fullpage({
                        // navigationPosition:true,
                        // autoScrolling:false,
                        loopBottom: true,

                        verticalCentered: false,
                        // sectionsColor: ['#1bbc9b', '#4BBFC3', '#7BAABE', 'whitesmoke', '#ccddff'],
                        scrollingSpeed: 1000,
                        slidesNavigation: true,
                        slidesNavPosition: 'bottom',
                        css3: true,
                        // ,autoScrolling:false
                        navigation: false,
                        afterLoad: function (anchorLink, index) {
                            if (index == 1) { 
                                $('.slogan').addClass('slogan-show')
                            } else {
                                $('.slogan').removeClass('slogan-show')
                            }

                            // if (index == 1) { 
                            //     $('.slogan').addClass('slogan-show')
                            // } else {
                            //     $('.slogan').removeClass('slogan-show')
                            // }
                        },
                        onLeave: function (index, slideIndex, direction) {
                            // alert(slideIndex)
                            // console.log('onLeave', slideIndex)

                            if (slideIndex == 1) {
                                video1.play()
                                // $('#page_header').addClass('hasTopBanner')
                                // $('.home-top-banner').show()
                                $('#partners_bg_1').attr('style', '')
                            } else {
                                // $('#page_header').removeClass('hasTopBanner')
                                // $('.home-top-banner').hide()
                                $('#partners_bg_1').attr('style', '')
                                
                            }
                            _this.slideIndex = slideIndex;
                            if (slideIndex == 2) {
                               _this.toggleAn(true);
                                $('#page_footer').addClass('fullpage-footer')
                            } else {
                                // _this.toggleAn(false);
                                $('#page_footer').removeClass('fullpage-footer')
                            }
                        },
                        afterSlideLoad: function (destiny, anchorLink, sectionIndex, slideAnchor, slideIndex) {}
                    })
                    _this.videoShowAfterPage = true
                } else {
                    _this.videoShowAfterPage = false
                }

                $('#homeSectionArrow').on('click', function () {
                    $('#fullpage').fullpage.moveSectionDown()
                })
            })
        },
        toggleAn(flag){
            var partners_title = $('.partners_content .title');
            var partners_icon_2 = $('#partners_icon_2');
            var partners_bg_1 = $('#partners_bg_1');
            var partners_bg_2 = $('#partners_bg_2');
            var animate_partners = true;
            partners_title.css('visibility', flag ? 'visible' : 'hidden')
            setTimeout(() => {
                if(flag){
                    partners_icon_2.show()
                    partners_bg_1.hide()
                    partners_bg_2.show()
                }else {
                    partners_icon_2.hide()
                    partners_bg_1.show()
                    partners_bg_2.hide()
                }
            }, 1000)
            TweenMax.from(partners_title, 1, {
                opacity: 0,
                delay: 0,
                y: 40,
            });
            TweenMax.from(partners_icon_2, 1, {
                y: 100,
                opacity: 0,
                delay: 1,
            });
            TweenMax.to(partners_bg_1, 1, {
                y: flag ? -300 : 0,
                scale: 0.9,
                opacity: 0,
                delay: 0,
            });
            TweenMax.from(partners_bg_2, 1, {
                scale: 1.5,
                opacity: 0,
                delay: 1,
            });
            TweenMax.from('.partners_list_line ', 1, {
                width: 0,
                delay: 1.2,
            });
            animate_partners = false
        }
    },
    destroyed(){
        clearInterval(this.swiperTime)
        this.swiperTime =null
    }
}
</script>
    <style>
* {
    padding: 0;
    margin: 0;
}
/* .home-top-banner {
    height: 164px;
    background-image: url('../assets/images/home/hometopbanner.png');
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    position: fixed;
    z-index: 99;
    background-position: 0 0;
}
.hasTopBanner {
    top: 164px !important;
}
.hasTopBanner .bg {
    opacity: 0.3 !important;
} */

.section {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.video-div {
    width: 100%;
    height: calc(100vh - 78px - 200px);
    margin-top: 78px;
    overflow: hidden;
    position: relative;
}
.video-div .slogan {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    height: 155px;
    z-index: 3;
    transition: top .6s;
}
.video-div .slogan-show {
    top: 50%;
}
.section .video {
    width: 100%;
    z-index: 1;
    height: calc(100vh - 78px - 200px);
    object-fit: cover;
    object-position: center;
}

.data-env-block {
    position: relative;
    perspective: 900;
    -webkit-perspective: 900;
}

.data-env-block .item-view {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 2;
    /* animation: moveitem 1s linear 0 infinite alternate both;
        -webkit-animation: moveitem 1s linear 0 infinite alternate both; */
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.data-env-block .item1 {
    top: 332px;
    margin-left: -652px;
    animation-name: moveitem1;
    -webkit-animation-name: moveitem1;
    /* -webkit-animation-duration: 3s;
        animation-duration: 3s; */
}

.data-env-block .item2 {
    top: 349px;
    margin-left: -250px;
    animation-name: moveitem2;
    -webkit-animation-name: moveitem2;
    /* -webkit-animation-duration: 3.2s;
        animation-duration: 3.2s; */
}

.data-env-block .item3 {
    top: 316px;
    margin-left: 123px;
    animation-name: moveitem3;
    -webkit-animation-name: moveitem3;
    /* -webkit-animation-duration: 2.8s;
        animation-duration: 2.8s; */
}

.data-env-block .item4 {
    top: 346px;
    margin-left: 493px;
    animation-name: moveitem4;
    -webkit-animation-name: moveitem4;
    /* -webkit-animation-duration: 2.6s;
        animation-duration: 2.6s; */
}

.data-env-block .item-view .item-title {
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    margin-top: 6px;
}

.data-env-block .item-view .item-number {
    margin-top: 6px;
}
.data-env-block .item-view .item-number .number-text {
    font-size: 54px;
    line-height: 65px;
}
.data-env-block .item-view .item-number .unit-text {
}
.css3-color {
    color: #e2ddd2;
    background: -webkit-linear-gradient(top, #eee1c7 0%, #a08c64 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: -moz-linear-gradient(top, #eee1c7 0%, #a08c64 100%);
    background-clip: text;
    -moz-text-fill-color: transparent;
    background: -o-linear-gradient(top, #eee1c7 0%, #a08c64 100%);
    -o-background-clip: text;
    -o-text-fill-color: transparent;
    background: -ms-linear-gradient(top, #eee1c7 0%, #a08c64 100%);
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
}

@keyframes moveitem1 {
    from {
        top: 308px;
    }
    50% {
        top: 408px;
    }
    to {
        top: 308px;
    }
}

@-weblit-keyframes moveitem1 {
    from {
        top: 308px;
    }
    50% {
        top: 408px;
    }
    to {
        top: 308px;
    }
}

@keyframes moveitem2 {
    from {
        top: 288px;
    }
    50% {
        top: 248px;
    }
    to {
        top: 288px;
    }
}

@-weblit-keyframes moveitem2 {
    from {
        top: 288px;
    }
    50% {
        top: 248px;
    }
    to {
        top: 288px;
    }
}

@keyframes moveitem3 {
    from {
        top: 338px;
    }
    50% {
        top: 268px;
    }
    to {
        top: 338px;
    }
}

@-weblit-keyframes moveitem3 {
    from {
        top: 338px;
    }
    50% {
        top: 268px;
    }
    to {
        top: 338px;
    }
}

@keyframes moveitem4 {
    from {
        top: 298px;
    }
    50% {
        top: 368px;
    }
    to {
        top: 298px;
    }
}

@-weblit-keyframes moveitem4 {
    from {
        top: 298px;
    }
    50% {
        top: 368px;
    }
    to {
        top: 298px;
    }
}

.home-section-arrow {
    position: fixed;
    z-index: 9;
    width: 48px;
    height: 48px;
    background: url(/images/home_arrow_icon.png);
    background-repeat: no-repeat;
    bottom: 30px;
    left: 50%;
    margin-left: -24px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: movearrow;
    -webkit-animation-name: movearrow;
    cursor: pointer;
}

@keyframes movearrow {
    from {
        bottom: 30px;
    }
    50% {
        bottom: 15px;
    }
    to {
        bottom: 30px;
    }
}

@-weblit-keyframes movearrow {
    from {
        bottom: 30px;
    }
    50% {
        bottom: 15px;
    }
    to {
        bottom: 30px;
    }
}

.toggle-video-voice {
    position: absolute;
    bottom: 122px;
    right: 24px;
    height: 90px;
    width: 90px;
    color: #fff;
    z-index: 99999;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/images/home_open_voice.png');
}
.voice-is-open {
    background-image: url('/images/home_close_voice.png');
}

</style>
<style lang="scss">
.home-page-fullpage {
    .data-link-con {
        padding: 20px 40px;
        display: flex;
        background: #fff;
        width: 100%;
        justify-content: space-between;
        box-sizing: border-box;
        height: 200px;
        
        .data-link-item {
            height: 100%;
            flex: 1;
            margin-right:30px;
            position: relative;
            &:last-child {
                margin-right: 0px;
            }
            .data-link-item-img {
                height: 100%;
            }
            img {
                width: 100%;
                height: 100%;
            }
            .data-link-text {
                font-size: 26px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 500;
                text-align: center;
                padding: 12px 0px;
                position: absolute;
                bottom: 0px;
                background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
                color: #fff;
                width: 100%;
            }
        }
    }
    /* 合作伙伴 */
    .partners_wrapper {
        position: relative;
        height: 100%;
        padding-top: 117px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .partners_wrapper .partners_content {
        width: 1200px;
        margin: 0 auto;
        position: relative;
    }

    .partners_wrapper .partners_content .title {
        width: 100%;
        font-size: 44px;
        color: #27313d;
        font-weight: 700;
        text-align: center;
    }

    .partners_wrapper .partners_content .partners_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }

    .partners_wrapper .partners_content .partners_icon div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .partners_wrapper .partners_content .partners_icon div span {
        margin-top: 10px;
        font-weight: 700;
        letter-spacing: 10px;
        font-size: 30px;
        color: #02478a;
    }

    .partners_wrapper .partners_content .partners_list {
        z-index: 1;
        top: 183px;
        position: absolute;
        margin-left: -100px;
        height: 235px;
        width: 1400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #02478a;
    }

    .partners_wrapper .partners_content .partners_list .partners_list_item {
        padding: 60px 0;
        display: flex;
        width: 1300px;
        justify-content: space-between;
        align-items: center;
    }

    .partners_wrapper .partners_content .partners_list .partners_list_item a:hover {
        opacity: 0.6;
    }

    .partners_wrapper .partners_content .partners_list .partners_list_line {
        height: 2px;
        width: 1300px;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0), #fff, rgba(0, 0, 0, 0));
    }

    .partners_wrapper .partners_bg {
        width: 100%;
        overflow: hidden;
        position: absolute;
        bottom: 72px;
        z-index: -1;
    }

    .partners_wrapper .partners_bg img {
        width: 100%;
        overflow-clip-margin: content-box;
        overflow: clip;
    }
    
}
.system-notice-dialog {
    text-align: center;
    .system-notice {
        margin: 0px auto;
    }
    .el-dialog__footer {
        text-align: center;
    }
    &.model-show {
        display: block;
    }
    &.model-hide {
        display: none;
    }
}
.v-modal.model-home.model-show {
    display: block;
}
.v-modal.model-home.model-hide {
    display: none;
}
.el-dialog__wrapper.model-home.model-hide {
    display: none;
}
</style>
